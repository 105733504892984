import React from 'react';
import { Layout, Menu, Typography, Card, Row, Col } from 'antd';
import './App.css';

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

function App() {
  return (
      <Layout>
        <Header>
          <div className="logo">郑州寻路科技有限公司</div>
          <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">首页</Menu.Item>
            <Menu.Item key="2">关于我们</Menu.Item>
            <Menu.Item key="3">服务项目</Menu.Item>
            <Menu.Item key="4">成功案例</Menu.Item>
            <Menu.Item key="5">联系我们</Menu.Item>
          </Menu>
        </Header>
        <Content style={{ padding: '50px' }}>
          <div className="site-layout-content">
            <Title level={2}>专业软件外包服务</Title>
            <p>我们专注于提供高质量的软件开发和技术解决方案，为您的企业创造价值。</p>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Card title="技术开发" bordered={false}>
                  提供全方位的技术开发服务
                </Card>
              </Col>
              <Col span={8}>
                <Card title="信息咨询" bordered={false}>
                  专业的信息技术咨询与支持
                </Card>
              </Col>
              <Col span={8}>
                <Card title="家政服务" bordered={false}>
                  高效优质的家政及清洁服务
                </Card>
              </Col>
            </Row>
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>
          <p>Copyright © 郑州寻路科技有限公司 ICP证：<span><a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备2023020967号-2</a></span></p>
        </Footer>
      </Layout>
  );
}

export default App;
